<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcFaithFund }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">page content goes here</div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'faith-fund',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.getComponentTranslations('security-banner')]).then(
        (results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        }
      )
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
